
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import ListSkeleton from '@/modules/shared/components/list-skeleton.component.vue';
import { Status } from '../../entities/status.entity';
import StatusService from '../../services/status.service';
import StatusOrderDialog from '../status-order/status-order.dialog.vue';

@Component({
  components: {
    StatusOrderDialog,
    ListSkeleton,
    SimplePagination,
  },
})
export default class StatusListComponent extends Vue {
  public status: Status[] = [];

  public showDialogOrder: boolean = false;

  public loading: boolean = false;

  public statusToOrder: Status[] = [];

  private get isManager() {
    return this.$permissionAccess.group.isSuperAdmin || this.$permissionAccess.isManager;
  }

  public get headers(): any {
    return this.$permissionAccess.group.isSuperAdmin
      ? [
        { text: 'Ordem', value: 'order_number', width: '2%' },
        { text: 'Descrição', value: 'description', width: '40%' },
        { text: 'Ativo?', value: 'activeText', width: '10%' },
        { text: 'Empresa', value: 'company_name', width: '10%' },
        {
          text: 'Ações',
          width: '10%',
          align: 'center',
          value: 'actions',
        },
      ]
      : [
        { text: 'Ordem', value: 'order_number', width: '2%' },
        { text: 'Descrição', value: 'description', width: '40%' },
        { text: 'Ativo?', value: 'activeText', width: '10%' },
        {
          text: 'Ações',
          width: '10%',
          align: 'center',
          value: 'actions',
        },
      ];
  }

  public setShowDialogOrder() {
    this.showDialogOrder = true;
  }

  public goToFormEdit(status: Status) {
    this.$router.push({
      name: 'status-edit',
      params: {
        id: status.id,
      },
    });
  }

  public goToFormCreate() {
    this.$router.push({
      name: 'status-create',
    });
  }

  public async getStatuses() {
    this.loading = true;
    const status = await StatusService.getAllStatusPaginate().finally(() => {
      this.loading = false;
    });
    this.status = status.map((statusItem: Status) => new Status(statusItem));
  }

  public async backPagination() {
    if (!StatusService.simplePaginationService.prevLink) return;
    const status = await StatusService.simplePaginationService.prev();
    this.status = status.map((statusItem: Status) => new Status(statusItem));
  }

  public async nextPagination() {
    if (!StatusService.simplePaginationService.nextLink) return;
    const status = await StatusService.simplePaginationService.next();
    this.status = status.map((statusItem: Status) => new Status(statusItem));
  }

  public async goToFirst() {
    if (!StatusService.simplePaginationService.firstLink) return;
    const status = await StatusService.simplePaginationService.goToFirst();
    this.status = status.map((statusItem: Status) => new Status(statusItem));
  }

  public async created() {
    this.getStatuses();
  }
}
