
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Status } from '../../entities/status.entity';
import { Company } from '../../../Companies/entities/company.entity';
import companiesService from '../../../Companies/services/companies.service';
import statusService from '../../services/status.service';

@Component({
  components: {
    draggable,
  },
  computed: { ...mapGetters(['user']) },
})
export default class StatusOrderDialog extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value?: Boolean;

  private status: Status[] = [];

  private user!: any;

  private companyId: string = '';

  private companies: Company[] = [];

  public drag: boolean = false;

  private get isManager() {
    return this.$permissionAccess.group.isSuperAdmin || this.$permissionAccess.isManager;
  }

  private async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private setCompanyId(companyId: string) {
    this.companyId = companyId;
    this.getStatuses();
  }

  public reorderStatus() {
    const statuses: any = this.status.map((stats: Status, index: number) => ({
      id: stats.id,
      order_number: index + 1,
    }));
    statusService
      .reorderStatus({ statuses, company_id: this.companyId })
      .then(() => {
        this.$snackbar.open({
          text: 'Status reodenados com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao reodenar os status',
          buttonColor: 'white',
          color: 'danger',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.$emit('input', false);
      });
  }

  public async getStatuses() {
    const status = await statusService.getStatusByCompanyId(this.companyId);
    this.status = status.map((statusItem: Status) => new Status(statusItem));
  }

  public created() {
    this.companyId = this.user ? this.user.company_id : '';
    if (!this.companyId) {
      this.getCompanies();
      return;
    }
    this.getStatuses();
  }
}
